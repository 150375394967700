<template>
  <div class="pu-box pu-box--right pu-box--lg is-open" @dragover="dragover" @dragleave="dragleave" @drop="drop">
    <div class="pu-box__wrap">
      <div :class="{'drag-over': isDropEvent}" class="pu-box__drop-zone drop-zone">
        <div class="drop-zone__wrap">
          <div class="drop-zone__message">
            <div class="drop-zone__message-icon">
              <svg class="svg" width="24" height="24">
                <use :href="'/img/sprite-' + appVersion + '.svg#upload-24'"/>
              </svg>
            </div>
            <div class="drop-zone__message-title">Перетащите файлы сюда</div>
            <div class="drop-zone__message-text">
              Поддерживаемые файлы: <strong v-html="request.supportedFileTypes.join(', ')"></strong><br>Размер не более {{request.maxFileUploadSize}}
            </div>
          </div>
        </div>
      </div>
      <div class="pu-box__top">
        <div class="pu-task-top">
          <div class="pu-task-top__wrap">
            <div class="pu-task-top__close">
              <button @click="closeModal" class="btn btn--4 btn--m btn--gray btn--icon pu-close" type="button">
                <span class="btn__icon">
                  <span class="icon icon--m">
                    <svg class="svg" width="24" height="24">
                      <use :href="'/img/sprite-' + appVersion + '.svg#cross-24'"/>
                    </svg>
                  </span>
                </span>
              </button>
            </div>
            <div class="pu-task-top__info">
              <div class="task-head">
                <span v-if="request.highPriority" class="task-head__important">
                  <span class="task-icon task-icon--urgent">
                    <svg class="svg" width="16" height="16">
                      <use :href="'/img/sprite-' + appVersion + '.svg#urgent-16'"/>
                    </svg>
                  </span>
                </span>
                <span class="task-head__number">№ {{request.id}}</span>
                <span class="task-head__date task-date">{{request.date}}</span>
              </div>
            </div>
            <div class="pu-task-top__ctrl">
              <button v-if="request.edit" @click="showEditForm" class="btn btn--4 btn--s btn--gray btn--icon" type="button">
                <span class="btn__icon">
                  <span class="icon icon--s">
                    <svg class="svg" width="16" height="16">
                      <use :href="'/img/sprite-' + appVersion + '.svg#edit-16'"/>
                    </svg>
                  </span>
                </span>
              </button>
              <button v-if="actions" v-for="action of actions" @click="changeStatus(action.id)" :class="{'btn--2': !action.is_forward, 'btn--1': action.is_forward}"
                      class="btn btn--m btn--primary" type="button">
                <span class="btn__label">{{action.name}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="pu-box__under-top">
        <div class="task-teas">
          <div class="task-teas__header">
            <div class="task-teas__title">{{request.name}}</div>
          </div>
        </div>
      </div>

      <div ref="main" class="pu-box__main">
        <div class="pu-box__body">
          <div class="pu-box__block">
            <div class="row">
              <div v-if="request.project" class="col col--half">
                <div class="group group--fs">
                  <div class="label">Проект</div>

                  <div class="project-item">
                    <div class="project-item__cover">
                      <svg v-if="request.project.type === 'personal'" class="svg" width="20" height="20">
                        <use :href="'/img/sprite-clr-' + appVersion +'.svg#male'"/>
                      </svg>
                      <template v-else>
                        <img v-if="request.project.logo" :src="request.project.logo" width="20" height="20" alt="">
                        <template v-else-if="request.project.emoji">{{request.project.emoji}}</template>
                        <svg v-else class="svg" width="24" height="24">
                          <use :href="'/img/sprite-' + appVersion + '.svg#logo-blank-24'" />
                        </svg>
                      </template>
                    </div>
                    <div class="project-item__name">{{request.project?.name}}</div>
                  </div>
                </div>
              </div>
              <div v-if="request.deadline" class="col col--half">
                <div class="group group--fs">
                  <div class="label">Дата и время</div>
                  <div class="value value--bg">{{deadline}} {{deadline_time}}</div>
                </div>
              </div>
            </div>
            <div v-if="request.project.type !== 'personal'" class="row">
              <div v-if="request.executors.length" class="col col--half">
                <div class="group">
                  <div class="label">Исполнители</div>
                  <div class="line">
                    <div v-for="executor of request.executors" class="line__item">
                      <div class="person-item">
                        <div class="person-item__photo">
                          <img class="person-item__img" :src="executor.photo" width="32" height="32" alt="">
                        </div>
                        <div class="person-item__name">{{executor.name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="request.initiator" class="col col--half">
                <div class="group group--fs">
                  <div class="label">Инициатор</div>

                  <div class="person-item">
                    <div class="person-item__photo">
                      <img class="person-item__img" :src="request.initiator?.photo" width="32" height="32" alt="">
                    </div>
                    <div class="person-item__name">{{request.initiator?.name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pu-box__block pu-box__block--border-top">
            <div class="task-related">
              <div class="task-related__link">
                <button @click="switchRelatedRequestBlock" class="btn btn--4 btn--m btn--primary btn--link" type="button">
                  <span class="btn__icon">
                    <span class="icon icon--s">
                      <svg class="svg" width="16" height="16">
                        <use :href="'/img/sprite-' + appVersion + '.svg#link-16'"/>
                      </svg>
                    </span>
                  </span>
                  <span v-if="!isShowAddRelatedRequest" class="btn__label">Добавить связанную задачу</span>
                  <span v-if="isShowAddRelatedRequest" class="btn__label">Закрыть</span>
                </button>
              </div>
              <div v-if="isShowAddRelatedRequest" class="task-related__search">
                <div class="form-group">
                  <div class="form-field form-field--text form-field--2 form-field--m form-field--primary">
                    <input v-model="searchRelatedRequest" type="text" class="form-control"
                           placeholder="Выберите из списка или введите название задачи">
                    <div class="form-field__side form-field__side--right">
                      <span class="icon icon--s form-icon">
                        <svg class="svg" width="16" height="16">
                          <use :href="'/img/sprite-' + appVersion + '.svg#search-16'"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isShowAddRelatedRequest && searchRelatedRequest" class="task-related__blank">
                <button @click="addNewRequestFromSearch" class="btn btn--4 btn--m btn--primary btn--link" type="button">
                  <span class="btn__icon">
                    <span class="icon icon--s">
                      <svg class="svg" width="16" height="16">
                        <use :href="'/img/sprite-' + appVersion + '.svg#add-16'"/>
                      </svg>
                    </span>
                  </span>
                  <span class="btn__label">{{searchRelatedRequest}}</span>
                </button>
              </div>
              <div class="task-related__main">
                <div v-if="isShowAddRelatedRequest" class="task-related__offers">
                  <div class="task-related__list">
                    <div v-for="request in suggestedRelatedRequests" class="task-related__item">
                      <div class="rel-task">
                        <a target="_blank" :href="'/requests?id=' + request.id" class="rel-task__main">
                          <div class="rel-task__info">
                            <div class="rel-task__title">{{request.name}}</div>
                          </div>
                        </a>
                        <div class="rel-task__extra">
                          <button @click="linkWithSuggestedRequest(request.id)" class="btn btn--4 btn--s btn--gray btn--icon" type="button">
                            <span class="btn__icon">
                              <span class="icon icon--s">
                                <svg class="svg" width="16" height="16">
                                  <use :href="'/img/sprite-' + appVersion + '.svg#add-16'"/>
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="relatedRequests && relatedRequests.length" class="task-related__my">
                  <div class="more-item more-item--xs more-item--gray more-item_clickable" :class="{'open': isShowRelatedRequests}">
                    <div @click="isShowRelatedRequests = !isShowRelatedRequests" class="more-item__top">
                      <div class="more-item__title">Связанные задачи</div>
                      <div class="more-item__marker">
                        <svg class="svg" width="16" height="16">
                          <use :href="'/img/sprite-' + appVersion + '.svg#arrow-down-16'"/>
                        </svg>
                      </div>
                    </div>
                    <div v-if="isShowRelatedRequests" class="more-item__main">
                      <div class="task-related__list">
                        <div v-for="request in relatedRequests" class="task-related__item">
                          <div class="rel-task">
                            <a target="_blank" :href="'/requests?id=' + request.id" class="rel-task__main">
                              <div class="rel-task__info">
                                <div class="rel-task__title">{{request.name}}</div>
                              </div>
                            </a>
                            <div class="rel-task__extra">
                              <button @click="deleteRelatedRequest(request.id)" class="btn btn--4 btn--s btn--gray btn--icon" type="button">
                                <span class="btn__icon">
                                  <span class="icon icon--s">
                                    <svg class="svg" width="16" height="16">
                                      <use :href="'/img/sprite-' + appVersion + '.svg#cross-16'"/>
                                    </svg>
                                  </span>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <request-comments v-if="comments" class="pu-box__footer" :comments="comments" :images="images" :request="request" :user="user"
                          @changeImages="changeImages" @scrollToEnd="scrollToEnd" @selectAnswerTo="selectAnswerTo" @editComment="editComment"
                          @deleteComment="deleteComment" @deleteFile="deleteFile"></request-comments>
      </div>

      <div class="pu-box__bottom" ref="createComment">
        <request-comment-form @createComment="createComment" @saveComment="createComment" @cancelAnswerTo="cancelAnswerTo" @cancelEdit="cancelEdit"
                              :droppedFiles="droppedFiles" :comment="comment" :user="user" :supportedMimeTypes="request.supportedMimeTypes"
                             :supportedFileTypes="request.supportedFileTypes" :maxFileUploadSize="request.maxFileUploadSize"
                             :maxFileUploadSizeInBytes="request.maxFileUploadSizeInBytes" :answerTo="answerTo">
        </request-comment-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import packageInfo  from '../../../../package';
import RequestComments from './RequestComments.vue';
import RequestCommentForm from './RequestCommentForm.vue';
import requestErrorModal from './RequestErrorModal.vue';

export default {
  computed: {
    requestErrorModal() {
      return requestErrorModal
    }
  },
  components: {RequestCommentForm, RequestComments},
  props: {
    request: Object,
    user: Object
  },
  data() {
    return {
      appVersion: packageInfo.version,
      actions: [],
      comments: [],
      images: [],
      isDropEvent: false,
      droppedFiles: [],
      answerTo: null,
      comment: null,
      isShowAddRelatedRequest: false,
      searchRelatedRequest: null,
      relatedRequests: null,
      suggestedRelatedRequests: null,
      isShowRelatedRequests: true,
      deadline: null,
      deadline_time: null
    }
  },
  mounted() {
    this.actions = this.request.workflow_actions;
    this.comments = this.request.comments;
    this.images = this.request.images;
    this.relatedRequests = this.request.relatedRequests;
    if (this.request.deadline && this.request.deadline_time) {
      this.deadline = this.getTimeZoneDateTime('date');
      this.deadline_time = this.getTimeZoneDateTime('time');
    } else if (this.request.deadline) {
      this.deadline = this.request.deadline;
    }
    setTimeout(() => {
      this.$refs.main.scrollTop = this.$refs.main.scrollHeight;
    }, '250');
  },
  methods: {
    getTimeZoneDateTime(type) {
      let date = new Date(this.request.deadline + ' ' + this.request.deadline_time);
      let timezone = new Date().getTimezoneOffset();
      timezone /= 60;
      if (timezone < 0) {
        date.setHours(date.getHours() + Math.abs(timezone));
      } else if (timezone > 0) {
        date.setHours(date.getHours() - Math.abs(timezone));
      }
      if (type === 'date') {
        return ('0' + date.getDate()).slice(-2) + '.' + ('0'+(date.getMonth()+1)).slice(-2) + '.' + date.getFullYear();
      } else {
        return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
    changeImages(images) {
      this.images = images;
    },
    scrollToEnd() {
      setTimeout(() => {
        this.$refs.main.scrollTop = this.$refs.main.scrollHeight;
      }, '250');
    },
    changeStatus(action) {
      axios.get('/change-request-status/' + this.request.id + '/' + action).then((res) => {
        axios.get('/get-request-modal/' + this.request.id).then((res) => {
          this.actions = res.data.workflow_actions;
        })
      })
    },
    selectAnswerTo(id) {
      this.answerTo = id;
    },
    editComment(comment) {
      this.comment = comment;
    },
    deleteComment(id) {
      axios.delete('/request-comments/' + id).then((res) => {
        axios.get('/get-request-modal/' + this.request.id).then((res) => {
          this.comments = res.data.comments;
        })
      });
    },
    deleteFile(id) {
      axios.delete('/delete-request-comment-file/' + id).then((res) => {
        axios.get('/get-request-modal/' + this.request.id).then((res) => {
          this.comments = res.data.comments;
        })
      });
    },
    cancelAnswerTo() {
      this.answerTo = null;
    },
    cancelEdit() {
      this.comment = null;
    },
    getNewComments() {
      axios.get('/get-request-modal/' + this.request.id).then((res) => {
        this.comments = res.data.comments;
        this.images = res.data.images;
        this.$nextTick(function() {
          this.$refs.main.scrollTop = this.$refs.main.scrollHeight;
        });
      })
    },
    createComment(e) {
      this.answerTo = null;
      let formData = new FormData;
      if (e.text) {
        formData.append('text', e.text);
      }
      if (e.answer_to) {
        formData.append('answer_to', e.answer_to);
      }
      for (let i = 0; i < e.files.length; i++) {
        if (i < 10) {
          formData.append('files[]', JSON.stringify(e.files[i]));
        } else {
          break;
        }
      }
      if (this.comment) {
        formData.append('_method', 'PUT');
        axios.post('/request-comments/' + this.comment.id, formData).then((res) => {
          this.comment = null;
          this.getNewComments();
        });
      } else {
        axios.post('/request-comments/' + this.request.id, formData).then((res) => {
          this.comment = null;
          this.getNewComments();
        });
      }
    },
    showEditForm() {
      this.$emit('showRequestEditModal');
    },
    dragover(event) {
      event.preventDefault();
      event.stopPropagation();
      this.isDropEvent = true;
    },
    dragleave(event) {
      event.preventDefault();
      event.stopPropagation();
      this.isDropEvent = false;
    },
    drop(event) {
      event.preventDefault();
      event.stopPropagation();
      this.isDropEvent = false;
      this.droppedFiles = event.dataTransfer.files;
    },
    getSuggestedRelatedRequests(search) {
      let url = '/get-requests-by-project/' + this.request.project_id + '/' + this.request.id;
      if (search) {
        url += '?q=' + search;
      }
      axios.get(url).then((res) => {
        this.suggestedRelatedRequests = res.data;
      })
    },
    switchRelatedRequestBlock() {
      if (!this.isShowAddRelatedRequest && this.request.project_id) {
        this.getSuggestedRelatedRequests();
      }
      this.searchRelatedRequest = null;
      this.isShowAddRelatedRequest = !this.isShowAddRelatedRequest;
    },
    linkWithSuggestedRequest(requestId) {
      axios.get('/link-with-request/' + this.request.id + '/' + requestId).then((res) => {
        this.relatedRequests = res.data;
        if (this.request.project_id) {
          this.getSuggestedRelatedRequests();
        }
      })
    },
    getRelatedRequests() {
      axios.get('/get-related-requests/' + this.request.id).then((res) => {
        this.relatedRequests = res.data;
        this.getSuggestedRelatedRequests();
      });
    },
    addNewRequestFromSearch() {
      if (this.searchRelatedRequest) {
        axios.post('/create-related-request/' + this.request.id, {name: this.searchRelatedRequest}).then(() => {
          this.searchRelatedRequest = null;
          this.getRelatedRequests();
        });
      }
    },
    deleteRelatedRequest(requestId) {
      axios.delete('/delete-related-request/' + this.request.id + '/' + requestId).then(() => {
        this.getRelatedRequests();
      });
    }
  },
  watch: {
    searchRelatedRequest(newVal) {
      this.getSuggestedRelatedRequests(newVal);
    }
  }
};
</script>