<template>
  <div class="main__wrap">
    <div class="main__content content content--wide" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <div :class="{'drag-over': isDropEvent}" class="pu-box__drop-zone drop-zone">
        <div class="drop-zone__wrap">
          <div class="drop-zone__message">
            <div class="drop-zone__message-icon">
              <svg class="svg" width="24" height="24">
                <use :href="'/img/sprite-' + appVersion + '.svg#upload-24'"/>
              </svg>
            </div>
            <div class="drop-zone__message-title">Перетащите файлы сюда</div>
            <div class="drop-zone__message-text">
              Поддерживаемые файлы: <strong v-html="supportedFileTypes.join(', ')"></strong><br>Размер не более {{maxFileUploadSize}}
            </div>
          </div>
        </div>
      </div>
      <div class="content__main">
        <div class="content__body">
          <div class="content__block">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="name" class="form-label">Название задачи<span v-if="dataChanged['name']" class="q-note q-note--saved">Сохранено</span></label>
                  <div class="form-field form-field--textarea form-field--2 form-field--l form-field--primary"
                       :class="{ 'has-error': !form.name && touched }">
                    <textarea id="name" @keyup="saveChanges" v-model="form.name" ref="name" type="text" class="form-control"
                              @input="resize()" rows="2" placeholder="Название задачи"></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="repeat_type" class="form-label">Тип повтора задачи <span v-if="dataChanged['repeat_type']" class="q-note q-note--saved">Сохранено</span></label>

                  <div class="form-field form-field--select form-field--2 form-field--m form-field--primary"
                       :class="{ 'has-error': !form.repeat_type && touched }">
                    <select id="repeat_type" ref="repeat_type" v-model="form.repeat_type" @change="saveChanges" class="form-control">
                      <option data-placeholder="true">Выберите тип повтора задачи</option>
                      <option v-for="repeat_type in repeat_types" :value="repeat_type.name">{{repeat_type.description}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col col--half">
                <div class="form-group">
                  <label for="repeat" class="form-label">Периодичность <template v-if="!form.repeat_type || form.repeat_type === 'new_request'">создания</template>
                    <template v-else>сброса статуса</template> задачи <span v-if="dataChanged['repeat']" class="q-note q-note--saved">Сохранено</span></label>

                  <div class="form-field form-field--select form-field--2 form-field--m form-field--primary"
                       :class="{ 'has-error': !form.repeat && touched }">
                    <select id="repeat" ref="repeat" v-model="form.repeat" @change="saveChanges" class="form-control">
                      <option data-placeholder="true">Выберите периодичность</option>
                      <option v-for="repeat in repeats" :value="repeat.name">{{repeat.description}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div v-if="form.repeat === 'several_days_in_week'" class="col col--half">
                <div class="form-group">
                  <label for="repeat_days_of_week" class="form-label">Дни недели <span v-if="dataChanged['repeat_days_of_week']" class="q-note q-note--saved">Сохранено</span></label>

                  <div class="form-field form-field--select form-field--2 form-field--m form-field--primary"
                       :class="{ 'has-error': !form.repeat_days_of_week && touched }">
                    <select id="repeat_days_of_week" ref="repeat_days_of_week" v-model="form.repeat_days_of_week" @change="saveChanges" class="form-control" multiple>
                      <option data-placeholder="true">Выберите дни недели</option>
                      <option v-for="day in days_of_week" :value="day.name">{{day.description}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="ready" class="row">
              <div class="col col--half">
                <div class="form-group">
                  <label for="date_begin" class="form-label">Дата начала действия шаблона <span v-if="dataChanged['date_begin']" class="q-note q-note--saved">Сохранено</span></label>
                  <div class="date-picker form-field form-field--text form-field--2 form-field--m form-field--primary"
                       :class="{ 'has-error': !form.date_begin && touched }">
                    <VueDatePicker id="date_begin" v-model="form.date_begin" :min-date="new Date()" locale="ru"
                                   :model-type="'yyyy-MM-dd'" auto-apply :enable-time-picker="false"
                                   :teleport="true" :dark="currentUser.setting.theme === 'dark'"
                                   :clearable="true" cancelText="Отмена" selectText="Выбрать" :format="dateFormat"
                                   :highlight="highlightedDates" @update:model-value="saveChanges('date_begin')"
                                   input-class-name="form-control"></VueDatePicker>
                  </div>
                </div>
              </div>

              <div class="col col--half">
                <div class="form-group">
                  <label for="time_create" class="form-label">Время создания
                    <template v-if="form.repeat_type && form.repeat_type === 'change_request_status'">и сброса статуса</template> задачи <span v-if="dataChanged['time_create']" class="q-note q-note--saved">Сохранено</span></label>
                  <div class="date-picker form-field form-field--text form-field--2 form-field--m form-field--primary"
                       :class="{ 'has-error': !form.time_create && touched }">
                    <VueDatePicker id="time_create" v-model="form.time_create" locale="ru" auto-apply time-picker
                                   :model-type="'HH:mm'" @update:model-value="saveChanges('time_create')"
                                   :teleport="true" :dark="currentUser.setting.theme === 'dark'"
                                   :clearable="true" cancelText="Отмена" selectText="Выбрать"
                                   input-class-name="form-control"></VueDatePicker>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="ready" class="row">
              <div class="col col--half">
                <div class="form-group">
                  <label for="repeat_until" class="form-label">Повторять до <span v-if="dataChanged['repeat_until']" class="q-note q-note--saved">Сохранено</span></label>
                  <div class="date-picker form-field form-field--text form-field--2 form-field--m form-field--primary">
                    <VueDatePicker id="repeat_until" v-model="form.repeat_until" :min-date="new Date()" locale="ru"
                                   :model-type="'yyyy-MM-dd'" auto-apply :enable-time-picker="false"
                                   :teleport="true" :dark="currentUser.setting.theme === 'dark'"
                                   :clearable="true" cancelText="Отмена" selectText="Выбрать" :format="dateFormat"
                                   :highlight="highlightedDates" @update:model-value="saveChanges('repeat_until')"
                                   input-class-name="form-control"></VueDatePicker>
                  </div>
                </div>
              </div>

              <div class="col col--half">
                <div class="form-group">
                  <label for="close_time" class="form-label">Время автоматического закрытия задачи <span v-if="dataChanged['close_time']" class="q-note q-note--saved">Сохранено</span></label>
                  <div class="date-picker form-field form-field--text form-field--2 form-field--m form-field--primary">
                    <VueDatePicker id="close_time" v-model="form.close_time" locale="ru" auto-apply time-picker
                                   :model-type="'HH:mm'" @update:model-value="saveChanges('close_time')"
                                   :teleport="true" :dark="currentUser.setting.theme === 'dark'"
                                   :clearable="true" cancelText="Отмена" selectText="Выбрать"
                                   input-class-name="form-control"></VueDatePicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="group">
                  <div class="form-label">Проект <span v-if="dataChanged['project_id']" class="q-note q-note--saved">Сохранено</span></div>
                  <div class="line">
                    <div v-for="project in mostUsedProjects" class="line__item">
                      <button class="project-item btn-item" type="button" @click="selectProject(project.id)"
                              :class="{'is-active': project.id === form.project_id}">
                        <div class="project-item__cover">
                          <svg v-if="project.type === 'personal'" class="svg" width="20" height="20">
                            <use :href="'/img/sprite-clr-' + appVersion +'.svg#male'"/>
                          </svg>
                          <template v-else>
                            <img v-if="project.logo" :src="project.logo" width="20" height="20" alt="">
                            <template v-else-if="project.emoji">{{project.emoji}}</template>
                            <svg v-else class="svg" width="24" height="24">
                              <use :href="'/img/sprite-' + appVersion + '.svg#logo-blank-24'" />
                            </svg>
                          </template>
                        </div>
                        <div class="project-item__name">{{project.name}}</div>
                      </button>
                    </div>
                  </div>
                  <div class="line">
                    <button @click="showMoreProjects" :class="{'is-active': isShowMoreProjects}" class="btn-add" type="button">
                        <span class="icon icon--s">
                          <svg class="svg" width="16" height="16">
                            <use :href="'/img/sprite-' + appVersion + '.svg#add-16'"/>
                          </svg>
                        </span>
                    </button>
                    <div v-if="isShowMoreProjects" v-for="project in projects" class="line__item">
                      <button class="project-item btn-item" type="button" @click="selectProject(project.id)"
                              :class="{'is-active': project.id === form.project_id}">
                        <div class="project-item__cover">
                          <svg v-if="project.type === 'personal'" class="svg" width="20" height="20">
                            <use :href="'/img/sprite-clr-' + appVersion +'.svg#male'"/>
                          </svg>
                          <template v-else>
                            <img v-if="project.logo" :src="project.logo" width="20" height="20" alt="">
                            <template v-else-if="project.emoji">{{project.emoji}}</template>
                            <svg v-else class="svg" width="24" height="24">
                              <use :href="'/img/sprite-' + appVersion + '.svg#logo-blank-24'" />
                            </svg>
                          </template>
                        </div>
                        <div class="project-item__name">{{project.name}}</div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="ready" class="row">
              <div class="col">
                <div class="group">
                  <div v-if="!project || (project.type !== 'personal')" class="task-teas__block">
                    <div class="group">
                      <div class="form-label">Исполнители <span v-if="dataChanged['executors']" class="q-note q-note--saved">Сохранено</span></div>
                      <div class="line">
                        <template v-if="!form.project_id">
                          <div class="line__item">
                            <button class="person-item btn-item" type="button" @click="selectExecutor(currentUser.id)"
                                    :class="{'is-active': form.executors.includes(currentUser.id)}">
                              <div class="person-item__photo">
                                <img class="person-item__img" :src="currentUser.photo" width="32" height="32" alt="">
                              </div>
                              <div class="person-item__name">Я</div>
                            </button>
                          </div>
                          <div v-for="user in mostUsedUsers" class="line__item">
                            <button class="person-item btn-item" type="button" @click="selectExecutor(user.id)"
                                    :class="{'is-active': form.executors.includes(user.id)}">
                              <div class="person-item__photo">
                                <img class="person-item__img" :src="user.photo" width="32" height="32" alt="">
                              </div>
                              <div class="person-item__name">{{user.name}}</div>
                            </button>
                          </div>
                          <button @click="showMoreUsers" :class="{'is-active': isShowMoreUsers}" class="btn-add btn-add--circle" type="button">
                          <span class="icon icon--s">
                            <svg class="svg" width="16" height="16">
                              <use :href="'/img/sprite-' + appVersion + '.svg#add-16'"/>
                            </svg>
                          </span>
                          </button>
                          <div v-if="isShowMoreUsers" v-for="user in users" class="line__item">
                            <button class="person-item btn-item" type="button" @click="selectExecutor(user.id)"
                                    :class="{'is-active': form.executors.includes(user.id)}">
                              <div class="person-item__photo">
                                <img class="person-item__img" :src="user.photo" width="32" height="32" alt="">
                              </div>
                              <div class="person-item__name">{{user.name}}</div>
                            </button>
                          </div>
                        </template>
                        <template v-if="form.project_id">
                          <div v-for="user in projectUsers" class="line__item">
                            <button class="person-item btn-item" type="button" @click="selectExecutor(user.id)"
                                    :class="{'is-active': form.executors.includes(user.id)}">
                              <div class="person-item__photo">
                                <img class="person-item__img" :src="user.photo" width="32" height="32" alt="">
                              </div>
                              <div class="person-item__name">{{user.name}}</div>
                            </button>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="group">
                  <div class="form-label">Приоритет <span v-if="dataChanged['priority_id']" class="q-note q-note--saved">Сохранено</span></div>
                  <div class="line">
                    <div v-for="priority in priorities" class="line__item">
                      <button class="priority-item btn-item" type="button" @click="changePriority(priority.id)"
                              :class="{'priority-item--normal': priority.slug !== 'high',
                                'priority-item--hight': priority.slug === 'high',
                                'is-active': priority.id === form.priority_id}">
                        <div class="priority-item__cover">
                            <span class="icon icon--s">
                              <svg class="svg" width="16" height="16">
                                <use :href="'/img/sprite-' + appVersion + '.svg#urgent-16'"/>
                              </svg>
                            </span>
                        </div>
                        <div class="priority-item__name">{{ priority.name }}</div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="comments" class="content__block">
            <request-comments class="pu-box__footer" :comments="comments" :images="images" :request="pattern" :user="currentUser"
                            @changeImages="changeImages" @selectAnswerTo="selectAnswerTo" @editComment="editComment"
                            @deleteComment="deleteComment" @deleteFile="deleteFile"></request-comments>
          </div>
          <div class="content__block">
            <request-comment-add-form ref="addComment" v-if="createData" @createComment="createComment"
                                      @filesUploadInProcess="getFilesUploadInProcess"
                                      :droppedFiles="droppedFiles" :supportedMimeTypes="supportedMimeTypes"
                                      :supportedFileTypes="supportedFileTypes" :maxFileUploadSize="maxFileUploadSize"
                                      :maxFileUploadSizeInBytes="maxFileUploadSizeInBytes">
            </request-comment-add-form>
            <request-comment-form v-if="editData" @createComment="createCommentToExistPattern" @saveComment="createCommentToExistPattern"
                                  @cancelAnswerTo="cancelAnswerTo" @cancelEdit="cancelEdit"
                                  :droppedFiles="droppedFiles" :comment="comment" :user="currentUser"
                                  :supportedMimeTypes="supportedMimeTypes" :supportedFileTypes="supportedFileTypes"
                                  :maxFileUploadSize="maxFileUploadSize" :maxFileUploadSizeInBytes="maxFileUploadSizeInBytes"
                                  :answerTo="answerTo">
            </request-comment-form>
          </div>
          <div class="content__block">
            <div class="row">
              <div class="col">
                <div v-if="error" class="login-box__message login-box__message_left">
                  <span class="form-error" v-html="error"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content__bottom content__bottom--bt">
        <div class="btns-wrap btns-wrap--sb">
          <button v-if="pattern" @click="deletePattern" class="btn btn--4 btn--m btn--primary" type="button">
            <span class="btn__label">Удалить</span>
          </button>

          <button v-if="pattern" @click="deactivatePattern" class="btn btn--4 btn--m btn--primary" type="button">
            <span v-if="!pattern.deleted_at" class="btn__label">Деактивировать</span>
            <span v-if="pattern.deleted_at" class="btn__label">Активировать</span>
          </button>

          <button v-if="!pattern" @click="addPattern" :disabled="!isValid" class="btn btn--1 btn--m btn--primary" type="button">
            <span class="btn__label">Добавить</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import packageInfo  from '../../../../package';
import SlimSelect from 'slim-select';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import RequestCommentAddForm from '../Requests/RequestCommentAddForm.vue';
import RequestComments from '../Requests/RequestComments.vue';
import RequestCommentForm from '../Requests/RequestCommentForm.vue';
import {ref} from 'vue';

export default {
  components: {VueDatePicker, RequestCommentAddForm, RequestCommentForm, RequestComments},
  props: {
    createData: Object,
    editData: Object,
  },
  data() {
    return {
      appVersion: packageInfo.version,
      error: null,
      isValid: false,
      init: true,
      ready: false,
      touched: null,
      form: {
        name: null,
        repeat_type: null,
        repeat: null,
        repeat_days_of_week: null,
        repeat_until: null,
        date_begin: null,
        time_create: null,
        close_time: null,
        priority_id: null,
        project_id: null,
        executors: []
      },
      pattern: null,
      allProjects: null,
      project: null,
      currentUser: null,
      isShowMoreProjects: false,
      isShowMoreUsers: false,
      mostUsedProjects: null,
      projects: null,
      mostUsedUsers: null,
      users: null,
      priorities: null,
      projectUsers: null,
      dataChanged: {},
      repeat_types: [
        {name: 'new_request', description: 'Каждый раз создавать новую задачу'},
        {name: 'change_request_status', description: 'Сбрасывать в начало статус выполнения одной и той же задачи'}
      ],
      repeats: [
        {name: 'daily', description: 'Ежедневно'},
        {name: 'several_days_in_week', description: 'Несколько раз в неделю'},
        {name: 'weekly', description: 'Еженедельно'},
        {name: 'two_weekly', description: 'Раз в две недели'},
        {name: 'monthly', description: 'Ежемесячно'},
        {name: 'quarterly', description: 'Ежеквартально'},
        {name: 'yearly', description: 'Ежегодно'},
      ],
      days_of_week: [
        {name: 'monday', description: 'Понедельник'},
        {name: 'tuesday', description: 'Вторник'},
        {name: 'wednesday', description: 'Среда'},
        {name: 'thursday', description: 'Четверг'},
        {name: 'friday', description: 'Пятница'},
        {name: 'saturday', description: 'Суббота'},
        {name: 'sunday', description: 'Воскресенье'},
      ],
      isDropEvent: false,
      droppedFiles: [],
      supportedMimeTypes: [],
      supportedFileTypes: [],
      maxFileUploadSize: null,
      maxFileUploadSizeInBytes: null,
      comment: null,
      filesUploadInProcess: false,
      dateFormat: null,
      highlightedDates: null,
      repeatTypeSelect: null,
      repeatSelect: null,
      repeatDaysOfWeek: null,
      requiredFields: ['name', 'repeat_type', 'repeat', 'date_begin', 'time_create'],
      timer: null,
      comments: [],
      images: [],
      answerTo: null
    }
  },
  mounted() {
    const date = ref(new Date());

    this.dateFormat = (date) => {
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    }

    this.highlightedDates = ref([
      "2025-01-01",
      "2025-01-02",
      "2025-01-03",
      "2025-01-04",
      "2025-01-05",
      "2025-01-06",
      "2025-01-07",
      "2025-01-08",
      "2025-01-11",
      "2025-01-12",
      "2025-01-18",
      "2025-01-19",
      "2025-01-25",
      "2025-01-26",
      "2025-02-01",
      "2025-02-02",
      "2025-02-08",
      "2025-02-09",
      "2025-02-15",
      "2025-02-16",
      "2025-02-22",
      "2025-02-23",
      "2025-03-01",
      "2025-03-02",
      "2025-03-08",
      "2025-03-09",
      "2025-03-15",
      "2025-03-16",
      "2025-03-22",
      "2025-03-23",
      "2025-03-29",
      "2025-03-30",
      "2025-04-05",
      "2025-04-06",
      "2025-04-12",
      "2025-04-13",
      "2025-04-19",
      "2025-04-20",
      "2025-04-26",
      "2025-04-27",
      "2025-05-01",
      "2025-05-02",
      "2025-05-03",
      "2025-05-04",
      "2025-05-08",
      "2025-05-09",
      "2025-05-10",
      "2025-05-11",
      "2025-05-17",
      "2025-05-18",
      "2025-05-24",
      "2025-05-25",
      "2025-05-31",
      "2025-06-01",
      "2025-06-07",
      "2025-06-08",
      "2025-06-12",
      "2025-06-13",
      "2025-06-14",
      "2025-06-15",
      "2025-06-21",
      "2025-06-22",
      "2025-06-28",
      "2025-06-29",
      "2025-07-05",
      "2025-07-06",
      "2025-07-12",
      "2025-07-13",
      "2025-07-19",
      "2025-07-20",
      "2025-07-26",
      "2025-07-27",
      "2025-08-02",
      "2025-08-03",
      "2025-08-09",
      "2025-08-10",
      "2025-08-16",
      "2025-08-17",
      "2025-08-23",
      "2025-08-24",
      "2025-08-30",
      "2025-08-31",
      "2025-09-06",
      "2025-09-07",
      "2025-09-13",
      "2025-09-14",
      "2025-09-20",
      "2025-09-21",
      "2025-09-27",
      "2025-09-28",
      "2025-10-04",
      "2025-10-05",
      "2025-10-11",
      "2025-10-12",
      "2025-10-18",
      "2025-10-19",
      "2025-10-25",
      "2025-10-26",
      "2025-11-02",
      "2025-11-03",
      "2025-11-04",
      "2025-11-08",
      "2025-11-09",
      "2025-11-15",
      "2025-11-16",
      "2025-11-22",
      "2025-11-23",
      "2025-11-29",
      "2025-11-30",
      "2025-12-06",
      "2025-12-07",
      "2025-12-13",
      "2025-12-14",
      "2025-12-20",
      "2025-12-21",
      "2025-12-27",
      "2025-12-28",
      "2025-12-31"
    ])

    this.repeatTypeSelect = new SlimSelect({
      select: this.$refs['repeat_type'],
      settings: {
        showSearch: false,
        allowDeselect: false
      }
    });

    this.repeatSelect = new SlimSelect({
      select: this.$refs['repeat'],
      settings: {
        showSearch: false,
        allowDeselect: false
      }
    });

    if (this.createData) {
      this.getData(this.createData);
      this.project = this.allProjects.find(x => x.type === 'personal');
      this.selectProject(this.project.id);

    } else if (this.editData) {
      this.getPattern(this.editData);
    }

    setTimeout(() => {
      this.init = false;
      this.ready = true;
    }, '250');
  },
  methods: {
    resize() {
      let element = this.$refs['name'];

      element.style.height = '40px';
      element.style.height = element.scrollHeight + 'px';
    },
    getData(object) {
      this.currentUser = object.currentUser;
      this.allProjects = object.allProjects;
      this.priorities = object.priorities;
      this.mostUsedProjects = object.mostUsedProjects;
      this.projects = object.projects;
      this.mostUsedUsers = object.mostUsedUsers;
      this.users = object.users;
      this.supportedMimeTypes = object.supportedMimeTypes;
      this.supportedFileTypes = object.supportedFileTypes;
      this.maxFileUploadSize = object.maxFileUploadSize;
      this.maxFileUploadSizeInBytes = object.maxFileUploadSizeInBytes;
    },
    getPattern(data) {
      this.pattern = data.pattern;
      this.comments = this.pattern.comments;
      this.images = this.pattern.images;
      this.form.name = this.pattern.name;
      this.form.repeat_type = this.pattern.repeat_type;
      this.form.repeat = this.pattern.repeat;
      if (this.pattern.repeat_days_of_week) {
        this.form.repeat_days_of_week = this.pattern.repeat_days_of_week.split(',');
      }
      this.form.repeat_until = this.pattern.repeat_until;
      this.form.date_begin = this.pattern.date_begin;
      this.form.time_create = this.pattern.time_create;
      this.form.close_time = this.pattern.close_time;
      this.form.priority_id = this.pattern.priority_id;
      this.form.project_id = this.pattern.project_id;
      this.form.time_create = this.getTimeZoneDateTime(this.form.time_create);

      if (this.pattern.close_time) {
        this.form.close_time = this.getTimeZoneDateTime(this.form.close_time);
      }

      this.repeatTypeSelect.setSelected(this.form.repeat_type);
      this.repeatSelect.setSelected(this.form.repeat);
      this.form.executors = [];
      if (this.pattern.executors) {
        for (let i = 0; i < this.pattern.executors.length; i++) {
          this.form.executors.push(this.pattern.executors[i].id);
        }
      }
      this.getData(data);
      if (this.form.project_id) {
        this.project = this.allProjects.find(x => x.id === this.form.project_id);
        if (this.project) {
          this.projectUsers = this.project.users;
        }
      }
    },
    showMoreProjects() {
      if (!this.isShowMoreProjects) {
        this.isShowMoreProjects = true;
      } else {
        this.isShowMoreProjects = false;
        if (!this.pattern) {
          if (this.projects.find(x => x.id === this.form.project_id)) {
            this.form.project_id = null;
          }
        }
      }
    },
    selectProject(e) {
      if (e !== this.form.project_id) {
        this.form.project_id = e;
        this.project = this.allProjects.find(x => x.id === this.form.project_id);
        this.projectUsers = this.project.users;
      } else {
        this.form.project_id = null;
        this.projectUsers = null;
      }
      this.form.executors = [];
      this.saveChanges('project_id');
    },
    selectExecutor(id) {
      if (this.form.executors.includes(id)) {
        this.form.executors.splice(this.form.executors.indexOf(id), 1)
      } else {
        this.form.executors.push(id);
      }
      this.saveChanges('executors');
    },
    showMoreUsers() {
      if (!this.isShowMoreUsers) {
        this.isShowMoreUsers = true;
      } else {
        this.isShowMoreUsers = false;
        if (!this.pattern) {
          this.form.executors = this.form.executors.filter(x => !this.users.map(a => a.id).includes(x));
        }
      }
    },
    changePriority(e) {
      if (this.form.priority_id === e) {
        this.form.priority_id = null;
      } else {
        this.form.priority_id = e
      }
      this.saveChanges('priority_id');
    },
    getUtcDateTime(time) {
      let date = new Date();
      let dateTime = new Date(date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2) + ' ' + time);

      let timezone = new Date().getTimezoneOffset();
      timezone /= 60;
      if (timezone < 0) {
        dateTime.setHours(dateTime.getHours() - Math.abs(timezone));
      } else if (timezone > 0) {
        dateTime.setHours(dateTime.getHours() + Math.abs(timezone));
      }
      return ("0" + dateTime.getHours()).slice(-2) + ":" + ("0" + dateTime.getMinutes()).slice(-2) + ":" + ("0" + dateTime.getSeconds()).slice(-2);
    },
    getTimeZoneDateTime(time) {
      let date = new Date();
      let dateTime = new Date(date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2) + ' ' + time);
      let timezone = new Date().getTimezoneOffset();
      timezone /= 60;
      if (timezone < 0) {
        dateTime.setHours(dateTime.getHours() + Math.abs(timezone));
      } else if (timezone > 0) {
        dateTime.setHours(dateTime.getHours() - Math.abs(timezone));
      }
      return ("0" + dateTime.getHours()).slice(-2) + ":" + ("0" + dateTime.getMinutes()).slice(-2);
    },
    createPatternFormData() {
      let formData;
      formData = new FormData;
      for (var key in this.form) {
        if (key === 'executors') {
          for (let i = 0; i < this.form.executors.length; i++) {
            formData.append('executors[]', this.form.executors[i]);
          }
        } else {
          if (this.form[key] !== null && this.form[key] !== "") {
            if ((this.form.time_create || this.form.close_time) && ['time_create', 'close_time'].includes(key)) {
              formData.append(key, this.getUtcDateTime(this.form[key]));
            } else {
              formData.append(key, this.form[key]);
            }
          }
        }
      }

      return formData;
    },
    validate() {
      if (this.init) {
        return;
      }
      this.isValid = false;
      this.touched = true;
      let isEmpty = false;
      for (var key in this.form) {
        if (!this.requiredFields.includes(key)) {
          continue;
        }
        if (this.form[key] == null || this.form[key] === "") {
          isEmpty = true;
        }
      }
      if (!isEmpty) {
        this.isValid = true;
      }
    },
    saveChanges(e) {
      if (!this.pattern) {
        this.validate();
      } else {
        if (this.init || !e) {
          return;
        }
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.dataChanged = {};
          this.savePattern(e.target ? e.target.id : e);
          this.touched = true;
        }, 200);
      }
    },
    deletePattern() {
      if (confirm("Вы уверены, что хотите удалить этот шаблон?")) {
        axios.delete('/settings/patterns/' + this.pattern.id + '?force=true').then(() => {
          location.href = '/settings/patterns';
        }).catch((err) => {
          this.error = err.response.data.message;
        });
      }
    },
    deactivatePattern() {
      if (confirm("Вы уверены, что хотите деактивировать этот шаблон?")) {
        axios.delete('/settings/patterns/' + this.pattern.id).then(() => {
          axios.get('/settings/patterns/' + this.pattern.id).then((res) => {
            this.init = true;
            this.getPattern(res.data);
            setTimeout(() => {
              this.init = false;
            }, '250');
          })
        }).catch((err) => {
          this.error = err.response.data.message;
        });
      }
    },
    addPattern() {
      if (this.isValid && !this.filesUploadInProcess) {
        this.$refs.addComment.saveComment();
        let formData = this.createPatternFormData();
        formData.append('timezone', new Date().getTimezoneOffset() / 60);
        if (this.comment) {
          if (this.comment.text) {
            formData.append('text', this.comment.text);
          }
          for (let i = 0; i < this.comment.files.length; i++) {
            if (i < 10) {
              formData.append('files[]', JSON.stringify(this.comment.files[i]));
            } else {
              break;
            }
          }
        }

        axios.post('/settings/patterns', formData).then(() => {
          location.href = '/settings/patterns';
        }).catch((err) => {
          this.error = err.response.data.error;
        });
      }
    },
    savePattern(field) {
      this.validate();
      if (this.isValid) {
        let formData = this.createPatternFormData();
        formData.append('_method', 'PUT');
        if (['time_create', 'close_time'].includes(field)) {
          formData.append('timezone', new Date().getTimezoneOffset() / 60);
        }

        axios.post('/settings/patterns/' + this.pattern.id, formData).then(() => {
          this.dataChanged[field] = true;
          setTimeout(() => {
            this.dataChanged = {};
          }, '2500');
        }).catch((err) => {
          this.error = err.response.data.error;
        });
      }
    },
    createComment(e) {
      this.comment = e;
    },
    getFilesUploadInProcess(e) {
      this.filesUploadInProcess = e;
    },
    dragover(event) {
      event.preventDefault();
      event.stopPropagation();
      this.isDropEvent = true;
    },
    dragleave(event) {
      event.preventDefault();
      event.stopPropagation();
      this.isDropEvent = false
    },
    drop(event) {
      event.preventDefault();
      event.stopPropagation();
      this.isDropEvent = false;
      this.droppedFiles = event.dataTransfer.files;
    },
    editComment(comment) {
      this.comment = comment;
    },
    selectAnswerTo(id) {
      this.answerTo = id;
    },
    changeImages(images) {
      this.images = images;
    },
    deleteComment(id) {
      axios.delete('/pattern-comments/' + id).then((res) => {
        axios.get('/settings/patterns/' + this.pattern.id).then((res) => {
          this.init = true;
          this.getPattern(res.data);
          setTimeout(() => {
            this.init = false;
          }, '250');
        })
      });
    },
    deleteFile(id) {
      axios.delete('/delete-pattern-comment-file/' + id).then((res) => {
        axios.get('/settings/patterns/' + this.pattern.id).then((res) => {
          this.init = true;
          this.getPattern(res.data);
          setTimeout(() => {
            this.init = false;
          }, '250');
        })
      });
    },
    cancelEdit() {
      this.comment = null;
    },
    createCommentToExistPattern(e) {
      this.answerTo = null;
      let formData = new FormData;
      if (e.text) {
        formData.append('text', e.text);
      }
      if (e.answer_to) {
        formData.append('answer_to', e.answer_to);
      }
      for (let i = 0; i < e.files.length; i++) {
        if (i < 10) {
          formData.append('files[]', JSON.stringify(e.files[i]));
        } else {
          break;
        }
      }
      if (this.comment) {
        formData.append('_method', 'PUT');
        axios.post('/pattern-comments/' + this.comment.id, formData).then((res) => {
          this.comment = null;
          axios.get('/settings/patterns/' + this.pattern.id).then((res) => {
            this.init = true;
            this.getPattern(res.data);
            setTimeout(() => {
              this.init = false;
            }, '250');
          })
        });
      } else {
        axios.post('/pattern-comments/' + this.pattern.id, formData).then((res) => {
          this.comment = null;
          axios.get('/settings/patterns/' + this.pattern.id).then((res) => {
            this.init = true;
            this.getPattern(res.data);
            setTimeout(() => {
              this.init = false;
            }, '250');
          })
        });
      }
    },
    cancelAnswerTo() {
      this.answerTo = null;
    },
  },
  watch: {
    'form.repeat': function(newVal, oldVal) {
      if (newVal === 'several_days_in_week') {
        setTimeout(() => {
          this.repeatDaysOfWeek = new SlimSelect({
            select: this.$refs['repeat_days_of_week'],
            settings: {
              showSearch: false,
              allowDeselect: false
            }
          });
        }, '250');
      } else {
        this.repeatDaysOfWeek = null;
        this.form.repeat_days_of_week = null;
      }
    }
  }
}
</script>