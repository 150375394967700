<template>
  <div class="k-board">
    <div class="k-board__wrap">
      <div class="k-board__row">
        <div v-for="(step, index) of steps" class="k-board__col" @drop="onDrop($event, index)"
             @dragenter.prevent @dragover.prevent>
          <div class="k-board-col">
            <div class="k-board-col__top">
              <div class="k-board-col__title">{{step.name}}</div>
              <div class="k-board-col__qty">{{step.amount}}</div>
            </div>

            <div class="k-board-col__main">
              <div :id="step.id" class="k-board-col__list">
                <div v-for="request of step.requests" draggable="true" @click="showModal(request.id)"
                     @dragstart="startDrag($event, request, index)"
                     @drop.stop="onDropSort($event, request, index)"
                     @dragover.prevent
                     @dragleave.prevent
                     @dragenter.prevent
                     class="k-board-card">
                  <div class="k-board-card__wrap">
                    <div class="k-board-card__top">
                      <div v-if="request.status.icon" class="k-board-card__marker">
                      <span class="task-icon" :class="request.status.className">
                        <svg class="svg" width="16" height="16">
                          <use :href="'/img/sprite-' + appVersion + '.svg#' + request.status.icon + '-16'"/>
                        </svg>
                      </span>
                      </div>
                      <div class="k-board-card__number">{{request.id}}</div>
                      <div v-if="request.unread_comments_amount > 0" class="k-board-card__qty"><span class="notify-qty">{{request.unread_comments_amount}}</span></div>
                      <div class="k-board-card__perfomers">
                        <div class="members">
                          <div v-for="executor in request.executors" class="members__item">
                            <div class="member">
                              <img :src="executor.photo" width="24" height="24" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="k-board-card__main">
                      <div class="k-board-card__title">{{request.name}}</div>
                      <div v-if="request.deadline && request.deadline_time" class="k-board-card__until">{{getTimeZoneDateTime('date', request)}} {{getTimeZoneDateTime('time', request)}}</div>
                      <div v-else class="k-board-card__until">{{request.deadline}}</div>
                      <div class="k-board-card__project">{{request.project?.name}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import packageInfo  from '../../../../package';
import axios from 'axios';

export default {
  props: {
    steps: Array
  },
  data() {
    return {
      appVersion: packageInfo.version
    }
  },
  methods: {
    getTimeZoneDateTime(type, request) {
      let date = new Date(request.deadline + ' ' + request.deadline_time);
      let timezone = new Date().getTimezoneOffset();
      timezone /= 60;
      if (timezone < 0) {
        date.setHours(date.getHours() + Math.abs(timezone));
      } else if (timezone > 0) {
        date.setHours(date.getHours() - Math.abs(timezone));
      }
      if (type === 'date') {
        return ('0' + date.getDate()).slice(-2) + '.' + ('0'+(date.getMonth()+1)).slice(-2) + '.' + date.getFullYear();
      } else {
        return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
      }
    },
    showModal(id) {
      this.$emit('showModal', id);
    },
    startDrag(event, item, list) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('itemId', item.id)
      event.dataTransfer.setData('itemList', list)
    },
    generateId(arr) {
      return arr.length ? Math.max(...arr.map(item => item.id)) + 1 : 0
    },
    getItemPosition(arr, itemId) {
      return arr.findIndex((item) => item.id == itemId)
    },
    getItemById(event, list) {
      const itemId = +event.dataTransfer.getData('itemId')
      const item = this.steps[list].requests.find((item) => item.id == itemId)
      return { item, itemId }
    },
    moveItem(itemList, list, itemPosition, droppedItemPosition, item) {
      let step = this.steps[list].id;
      let action = item['workflow_actions'].find(x => x.next_step == step);
      if (action) {
        this.steps[itemList].requests.splice(itemPosition, 1)
        this.steps[list].requests.splice(droppedItemPosition, 0, {...item})
        this.$emit('changeStatus', this.steps);

        axios.get('/change-request-status/' + item.id + '/' + action.id + '?viewType=board').then((res) => {})
      }
    },
    changeList(event, list, droppedItem, listNumber) {
      const { item, itemId } = this.getItemById(event, listNumber)

      const itemPosition = this.getItemPosition(list, itemId)
      const droppedItemPosition = this.getItemPosition(list, droppedItem.id)
      list.splice(itemPosition, 1)
      list.splice(droppedItemPosition, 0, item)
      if (itemPosition !== droppedItemPosition) {
        axios.get('/change-request-board-order-number/' + item.id + '/' + droppedItemPosition + '?viewType=board').then((res) => {})
      }
    },
    onDropSort(event, droppedItem, list) {
      const itemList = +event.dataTransfer.getData('itemList');
      const { item, itemId } = this.getItemById(event, itemList)

      const itemPosition = this.getItemPosition(this.steps[itemList].requests, itemId)

      const droppedItemPosition = this.getItemPosition(this.steps[list].requests, droppedItem.id)

      if (itemList !== list) {
        this.moveItem(itemList, list, itemPosition, droppedItemPosition, item)
        return
      }

      this.changeList(event, this.steps[list].requests, droppedItem, list)
    },
    mutationArray(from, to, item, itemId) {
      const itemPosition = this.getItemPosition(from, itemId)
      from.splice(itemPosition, 1)
      to.push(item)
    },
    onDrop(event, list) {
      const itemList = +event.dataTransfer.getData('itemList')
      const { item, itemId } = this.getItemById(event, itemList)

      if (itemList !== list) {

      }
      this.mutationArray(this.steps[itemList].requests, this.steps[list].requests, item, itemId)

      let step = this.steps[list].id;
      let action = item['workflow_actions'].find(x => x.next_step == step);
      if (action) {
        this.$emit('changeStatus', this.steps);

        axios.get('/change-request-status/' + item.id + '/' + action.id + '?viewType=board').then((res) => {})
      }
    }
  }
};
</script>